import React from 'react';
import { useIntersectionObserver } from '../hooks/useIntersectionObserver';

const AnimatedSection = ({ 
  children, 
  className = '', 
  animation = 'fade-in-up',
  threshold = 0.1,
  delay = 0 
}) => {
  const [ref, isVisible] = useIntersectionObserver({
    threshold,
    rootMargin: '0px'
  });

  return (
    <div
      ref={ref}
      className={`
        ${className}
        transition-all duration-700 ease-out
        ${isVisible 
          ? `opacity-100 translate-y-0 animate-${animation}`
          : 'opacity-0 translate-y-10'
        }
      `}
      style={{ transitionDelay: `${delay}ms` }}
    >
      {children}
    </div>
  );
};

export default AnimatedSection;
