import React, { useState, useEffect } from 'react';
import { 
  ChevronDown, 
  DollarSign, 
  Truck, 
  CheckCircle, 
  Star, 
  MessageCircle, 
  Recycle,
  Leaf,
  RefreshCw,
  Timer,
  Users,
  Award,
  Facebook
} from 'lucide-react';
import Header from './components/Header';
import AnimatedSection from './components/AnimatedSection';
import WhatsAppButton from './components/WhatsAppButton';
import ScrollToTop from './components/ScrollToTop';
import CoverageNotice from './components/CoverageNotice';
import CTAButton from './components/CTAButton';
import { useCountUp } from './hooks/useCountUp';

const testimonials = [
  { id: 1, name: 'Juan Pérez', text: 'Excelente servicio, muy rápido y el mejor precio del mercado.' },
  { id: 2, name: 'María González', text: 'Proceso muy sencillo. Recomiendo ampliamente VendeTuCatalizador.' },
  { id: 3, name: 'Carlos Rodríguez', text: 'Increíble lo fácil que fue vender mi catalizador. Gracias por su profesionalismo.' },
];

const FAQ = [
  { 
    question: '¿Cuánto tiempo tarda el proceso?', 
    answer: 'El proceso generalmente toma entre 24 a 48 horas desde que recibes tu cotización hasta que recibes tu pago.' 
  },
  { 
    question: '¿Cómo se determina el precio de mi catalizador?', 
    answer: 'El precio se determina basándose en el modelo específico del catalizador que deseas vender. Requerimos conocer específicamente el catalizador que deseas vender ya que existen muchas opciones en el mercado.\nPor eso te pedimos que completes nuestro formulario de cotización para que podamos ofrecerte el mejor precio posible.' 
  },
  { 
    question: '¿Ofrecen servicio en toda la República Mexicana?', 
    answer: 'Por el momento solo contamos con servicio de recogida a domicilio en CDMX y algunos municipios del Estado de México. Sin embargo, podemos recibir tu catalizador por paquetería desde cualquier lugar de la República Mexicana. Tu pago está 100% garantizado.' 
  },
  { 
    question: '¿Es legal vender mi catalizador usado?', 
    answer: 'Sí, es completamente legal vender tu catalizador usado. Nosotros nos encargamos de su reciclaje responsable.' 
  },
  { 
    question: '¿Cómo se realiza el pago?', 
    answer: 'Ofrecemos varias opciones de pago, incluyendo transferencia bancaria y efectivo. Puedes elegir la que más te convenga.' 
  },
  {
    question: '¿Qué documentación necesito para vender mi catalizador?',
    answer: 'Para vender tu catalizador solo necesitas una identificación oficial vigente. No es necesario presentar documentación del vehículo.'
  },
  {
    question: '¿Puedo vender varios catalizadores al mismo tiempo?',
    answer: 'Sí, aceptamos múltiples catalizadores en una sola transacción. Cada uno será evaluado individualmente para ofrecerte el mejor precio.'
  },
  {
    question: '¿Qué marcas de catalizadores compran?',
    answer: 'Compramos catalizadores de todas las marcas y modelos de vehículos. Esto incluye automóviles, camionetas, motocicletas y vehículos comerciales.'
  },
  {
    question: '¿El catalizador debe estar en buen estado?',
    answer: 'Compramos catalizadores en cualquier estado. Sin embargo, el precio puede variar dependiendo de su condición y contenido de metales preciosos.'
  }
];

const sections = ['inicio', 'como-funciona', 'impacto-ambiental', 'por-que-elegirnos', 'testimonios', 'preguntas-frecuentes'];

export default function VendeTuCatalizadorApp() {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [activeSection, setActiveSection] = useState('inicio');
  const catalCount = useCountUp('1000', 2500);
  const hoursCount = useCountUp(24, 2000);
  const satisfactionCount = useCountUp(100, 2000);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight / 2;
      
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const { top } = element.getBoundingClientRect();
          const elementPosition = window.scrollY + top;
          
          if (scrollPosition >= elementPosition && scrollPosition <= elementPosition + element.offsetHeight) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const event = new CustomEvent('sectionChange', { detail: activeSection });
    window.dispatchEvent(event);
  }, [activeSection]);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="font-sans bg-gradient-to-b from-gray-50 to-blue-50 text-gray-800 relative">
      <WhatsAppButton />
      <ScrollToTop />
      <Header activeSection={activeSection} />

      <main className="overflow-hidden">
        {/* Hero Section */}
        <section id="inicio" className="bg-gradient-to-br from-blue-600 via-blue-500 to-blue-400 text-white min-h-screen flex flex-col justify-between pt-28 md:pt-32">
          <div className="flex-grow flex items-center">
            <div className="container mx-auto px-4">
              <div className="flex flex-col md:flex-row items-center justify-between">
                <AnimatedSection animation="fade-in-up" className="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
                  <span className="inline-block bg-white/20 backdrop-blur-sm text-white text-xs px-3 py-1 rounded-full uppercase tracking-wide mb-4">
                    VENDETUCATALIZADOR.COM.MX
                  </span>
                  <h1 className="text-3xl md:text-5xl font-bold mb-6">
                    <span className="block">Vende tu Catalizador</span>
                    <span className="block text-blue-200">Rápidamente y al Mejor Precio</span>
                  </h1>
                  <CTAButton />
                </AnimatedSection>
                
                <AnimatedSection animation="fade-in" delay={300} className="w-full md:w-1/2 flex justify-center md:justify-end relative">
                  <div className="absolute inset-0 bg-gradient-radial from-blue-400/20 to-transparent rounded-full blur-3xl transform -translate-x-1/2"></div>
                  <div className="absolute inset-0 bg-gradient-radial from-blue-300/10 to-transparent rounded-full blur-2xl translate-x-1/4"></div>
                  
                  <img 
                    src={process.env.PUBLIC_URL + '/images/PAGOSEGURO.png'} 
                    alt="Representación de servicio rápido y mejor precio" 
                    className="max-w-full h-auto relative z-10"
                  />
                </AnimatedSection>
              </div>
            </div>
          </div>

          <div className="text-center pb-8 mt-8 md:mt-0">
            <p className="mb-2">Checa cómo lo hacemos</p>
            <ChevronDown className="mx-auto animate-bounce" size={24} />
          </div>
        </section>

        {/* Stats and How it Works Section */}
        <section id="como-funciona" className="py-12 bg-white/80 backdrop-blur-sm">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                { icon: <Award size={40} />, number: catalCount, text: "Catalizadores Reciclados" },
                { icon: <Timer size={40} />, number: `${hoursCount}h`, text: "Tiempo Promedio de Pago" },
                { icon: <Users size={40} />, number: `${satisfactionCount}%`, text: "Clientes Satisfechos" }
              ].map((stat, index) => (
                <AnimatedSection key={index} delay={index * 200}>
                  <div className="text-center p-6 rounded-lg bg-gradient-to-br from-blue-50 to-white shadow-lg">
                    <div className="text-blue-500 mb-4">{stat.icon}</div>
                    <div className="text-4xl font-bold text-blue-600 mb-2">{stat.number}</div>
                    <div className="text-gray-600">{stat.text}</div>
                  </div>
                </AnimatedSection>
              ))}
            </div>

            <div className="mt-20">
              <AnimatedSection>
                <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">¿Cómo Funciona?</h2>
              </AnimatedSection>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                {[
                  { icon: <ChevronDown size={40} />, title: "Identifica el Código", description: "Usa nuestra guía visual para encontrar el código de tu catalizador." },
                  { icon: <MessageCircle size={40} />, title: "Completa el Formulario", description: "Ingresa detalles de tu catalizador y tu información de contacto." },
                  { icon: <Truck size={40} />, title: "Elige Método de Entrega", description: "Opta por recogida a domicilio o entrega en sucursal." },
                  { icon: <DollarSign size={40} />, title: "Tu Dinero en Mano", description: "Una vez verifiquemos tu catalizador, recibe tu dinero." },
                ].map((step, index) => (
                  <AnimatedSection key={index} delay={index * 200}>
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 text-center group hover:bg-gradient-to-br hover:from-blue-50 hover:to-white">
                      <div className="bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-full p-4 inline-block mb-4 group-hover:scale-110 transition-transform duration-300">
                        {step.icon}
                      </div>
                      <h3 className="font-semibold text-xl mb-2 text-blue-700">{step.title}</h3>
                      <p className="text-gray-600">{step.description}</p>
                    </div>
                  </AnimatedSection>
                ))}
              </div>
              <div className="text-center mt-12">
                <CTAButton className="mt-8" />
              </div>
            </div>
          </div>
        </section>

        {/* Environmental Impact Section */}
        <section id="impacto-ambiental" className="py-20 bg-gradient-to-br from-green-50 to-blue-50">
          <div className="container mx-auto px-4">
            <AnimatedSection>
              <h2 className="text-4xl font-bold text-center mb-16 text-green-600">Impacto Ambiental</h2>
            </AnimatedSection>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
              <div className="space-y-6">
                {[
                  { icon: <Recycle size={24} />, title: "Reciclaje Responsable", description: "Cada catalizador reciclado contribuye a la reducción de la minería y la conservación de metales preciosos." },
                  { icon: <Leaf size={24} />, title: "Reducción de Residuos", description: "Evitamos que los catalizadores terminen en vertederos, dándoles una segunda vida útil." },
                  { icon: <RefreshCw size={24} />, title: "Economía Circular", description: "Fomentamos un ciclo sostenible de reutilización y reciclaje de materiales valiosos." }
                ].map((item, index) => (
                  <AnimatedSection key={index} delay={index * 200}>
                    <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 hover:bg-gradient-to-br hover:from-green-50 hover:to-white">
                      <div className="flex items-center gap-3 mb-3">
                        <div className="text-green-500">{item.icon}</div>
                        <h3 className="text-xl font-semibold text-green-600">{item.title}</h3>
                      </div>
                      <p className="text-gray-600">{item.description}</p>
                    </div>
                  </AnimatedSection>
                ))}
              </div>
              <AnimatedSection animation="fade-in" delay={300}>
                <div className="relative group">
                  <div className="absolute inset-0 bg-gradient-radial from-green-400/30 to-transparent rounded-full blur-3xl group-hover:scale-110 transition-transform duration-500"></div>
                  <div className="absolute inset-0 bg-gradient-radial from-blue-400/20 to-transparent rounded-full blur-2xl -translate-x-1/4 group-hover:translate-x-0 transition-transform duration-500"></div>
                  
                  <img 
                    src={process.env.PUBLIC_URL + '/images/CLAP.png'} 
                    alt="Impacto ambiental - Juntos por un futuro sostenible" 
                    className="relative z-10 transform hover:scale-105 transition-transform duration-300 w-full max-w-sm mx-auto filter drop-shadow-xl"
                  />
                  
                  <div className="absolute top-1/4 left-1/4 w-8 h-8 bg-green-400/30 rounded-full blur-sm animate-pulse"></div>
                  <div className="absolute bottom-1/4 right-1/4 w-6 h-6 bg-blue-400/30 rounded-full blur-sm animate-pulse delay-150"></div>
                </div>
              </AnimatedSection>
            </div>
            <div className="text-center mt-12">
              <CTAButton className="mt-8" />
            </div>
          </div>
        </section>

        {/* Why Choose Us Section */}
        <section id="por-que-elegirnos" className="py-20 bg-gradient-to-b from-blue-50 to-white">
          <div className="container mx-auto px-4">
            <AnimatedSection>
              <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">¿Por Qué Elegirnos?</h2>
            </AnimatedSection>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                { icon: <DollarSign size={30} />, title: "Cotizaciones Justas y Competitivas", description: "Ofrecemos los mejores precios del mercado por tu catalizador usado." },
                { icon: <CheckCircle size={30} />, title: "Proceso Rápido y Sencillo", description: "Nuestro sistema está diseñado para ahorrarte tiempo y esfuerzo." },
                { icon: <Star size={30} />, title: "Pago Seguro y Confiable", description: "Garantizamos transacciones seguras y transparentes." },
                { icon: <Truck size={30} />, title: "Opciones Flexibles de Entrega", description: "Adaptamos nuestro servicio a tu conveniencia." },
              ].map((reason, index) => (
                <AnimatedSection key={index} delay={index * 200}>
                  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 flex items-start group">
                    <div className="bg-gradient-to-br from-blue-500 to-blue-600 text-white rounded-full p-2 mr-4 group-hover:scale-110 transition-transform duration-300">
                      {reason.icon}
                    </div>
                    <div>
                      <h3 className="font-semibold text-xl mb-2 text-blue-700">{reason.title}</h3>
                      <p className="text-gray-600">{reason.description}</p>
                    </div>
                  </div>
                </AnimatedSection>
              ))}
            </div>
            <div className="text-center mt-12">
              <CTAButton className="mt-8" />
            </div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section id="testimonios" className="py-20 bg-gradient-to-br from-blue-50 to-white">
          <div className="container mx-auto px-4">
            <AnimatedSection>
              <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">Lo que dicen nuestros clientes</h2>
            </AnimatedSection>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <AnimatedSection key={testimonial.id} delay={index * 200}>
                  <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 relative group">
                    <div className="absolute inset-0 bg-gradient-to-br from-blue-50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg"></div>
                    <div className="absolute top-0 right-0 -mt-2 -mr-2 bg-gradient-to-br from-yellow-400 to-yellow-500 text-white rounded-full p-2">
                      <Star size={16} />
                    </div>
                    <p className="mb-4 italic text-gray-600">"{testimonial.text}"</p>
                    <div className="flex items-center justify-end">
                      <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-2">
                        {testimonial.name.charAt(0)}
                      </div>
                      <p className="font-semibold text-blue-600">{testimonial.name}</p>
                    </div>
                  </div>
                </AnimatedSection>
              ))}
            </div>
            <div className="text-center mt-12">
              <CTAButton className="mt-8" />
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section id="preguntas-frecuentes" className="py-20 bg-gradient-to-b from-white to-blue-50">
          <div className="container mx-auto px-4">
            <AnimatedSection>
              <h2 className="text-4xl font-bold text-center mb-16 text-blue-600">Preguntas Frecuentes</h2>
            </AnimatedSection>
            <div className="max-w-3xl mx-auto">
              {FAQ.map((item, index) => (
                <AnimatedSection key={index} delay={index * 100}>
                  <div className="mb-4">
                    <button
                      className="flex justify-between items-center w-full p-4 bg-white hover:bg-blue-50 rounded-lg focus:outline-none transition-colors duration-300 shadow-md hover:shadow-lg"
                      onClick={() => toggleAccordion(index)}
                    >
                      <span className="font-semibold text-blue-700">{item.question}</span>
                      <ChevronDown
                        className={`transform transition-transform ${
                          activeAccordion === index ? 'rotate-180' : ''
                        } text-blue-500`}
                      />
                    </button>
                    {activeAccordion === index && (
                      <div className="p-4 bg-blue-50 rounded-b-lg">
                        <p className="text-gray-600">{item.answer}</p>
                      </div>
                    )}
                  </div>
                </AnimatedSection>
              ))}
            </div>
            <div className="text-center mt-12">
              <CTAButton className="mt-8" />
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section id="cotizacion" className="py-4 bg-gradient-to-br from-blue-600 via-blue-500 to-blue-400 text-white">
          <div className="container mx-auto text-center px-4">
            <AnimatedSection>
              <div className="flex justify-center mb-4 relative group">
                <div className="absolute inset-0 bg-gradient-radial from-blue-400/20 to-transparent rounded-full blur-3xl transform scale-150 group-hover:scale-175 transition-transform duration-500"></div>
                <div className="absolute inset-0 bg-gradient-radial from-blue-300/10 to-transparent rounded-full blur-2xl -translate-x-1/4 group-hover:translate-x-0 transition-transform duration-500"></div>
                
                <img 
                  src={process.env.PUBLIC_URL + '/images/CHOCALAS.png'} 
                  alt="Representación de servicio rápido y mejor precio" 
                  className="w-1/2 md:w-1/4 relative z-10 transform group-hover:scale-105 transition-transform duration-300"
                />
              </div>
              <span className="inline-block bg-blue-600 text-white text-xs px-3 py-1 rounded-full uppercase tracking-wide mb-4">
                VENDETUCATALIZADOR.COM.MX
              </span>
              <h2 className="text-4xl font-bold mb-8">Obtén tu Cotización Ahora</h2>
              <p className="mb-8 text-xl">Completa nuestro formulario y recibe tu cotización en tu WhatsApp lo más pronto posible.</p>
              <CTAButton />
              <CoverageNotice />
            </AnimatedSection>
          </div>
        </section>
      </main>

      <footer className="bg-gradient-to-br from-blue-700 to-blue-800 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <AnimatedSection>
              <div className="text-center md:text-left">
                <h3 className="text-xl font-semibold mb-4">Contacto</h3>
                <a 
                  href="mailto:soporte@vendetucatalizador.com.mx" 
                  className="text-blue-200 hover:text-white transition-colors block"
                >
                  soporte@vendetucatalizador.com.mx
                </a>
                <a 
                  href="https://wa.me/525612684036?text=¡Hola!%20Me%20interesa%20vender%20mi%20catalizador,%20¿podrían%20ayudarme?" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-blue-200 hover:text-white transition-colors block"
                >
                  WhatsApp: +52 56 1268 4036
                </a>
              </div>
            </AnimatedSection>
            
            <AnimatedSection delay={100}>
              <div className="text-center md:text-left">
                <h3 className="text-xl font-semibold mb-4">Horario de Atención</h3>
                <p className="text-blue-200">Lunes a Viernes: 9:00 AM - 6:00 PM</p>
                <p className="text-blue-200">Sábado: 9:00 AM - 2:00 PM</p>
              </div>
            </AnimatedSection>
            
            <AnimatedSection delay={200}>
              <div className="text-center md:text-left">
                <h3 className="text-xl font-semibold mb-4">Síguenos</h3>
                <a 
                  href="https://www.facebook.com/profile.php?id=61554258313707&mibextid=ZbWKwL" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="inline-flex items-center gap-2 text-blue-200 hover:text-white transition-colors"
                >
                  <Facebook className="w-5 h-5" />
                  <span>Facebook</span>
                </a>
              </div>
            </AnimatedSection>
          </div>
          
          <div className="border-t border-blue-600 pt-8 mt-8 text-center">
            <p className="text-blue-200">&copy; 2024 VendeTuCatalizador.com.mx - Todos los derechos reservados</p>
          </div>
        </div>
      </footer>
    </div>
  );
}
