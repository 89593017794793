import React from 'react';
import { Shield } from 'lucide-react';

const CoverageNotice = () => {
  return (
    <div className="bg-blue-50/50 backdrop-blur-sm rounded-lg p-6 mt-8 border border-blue-100 shadow-lg">
      <div className="flex flex-col items-center gap-6">
        <div className="text-center">
          <h4 className="text-lg font-semibold text-blue-800 mb-4 flex items-center justify-center gap-2">
            <Shield className="w-5 h-5 text-blue-600" />
            Cobertura de Servicio
          </h4>
          <p className="text-blue-700 max-w-2xl mx-auto">
            Por el momento solo contamos con servicio de recogida a domicilio y recepción en sucursal en <span className="font-bold">CDMX y algunos municipios del Estado de México</span>. Sin embargo, podemos recibir tu catalizador por paquetería desde cualquier lugar de la República Mexicana.
          </p>
        </div>

        <div className="flex justify-center">
          <span className="inline-flex items-center gap-2 bg-blue-600 text-white text-sm px-4 py-2 rounded-full">
            <Shield className="w-4 h-4" />
            Pago 100% Garantizado
          </span>
        </div>
      </div>
    </div>
  );
};

export default CoverageNotice;
