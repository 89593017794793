import { useState, useEffect } from 'react';

export const useCountUp = (end, duration = 2000, start = 0) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    let startTime = null;
    const animateCount = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const percentage = Math.min(progress / duration, 1);
      
      if (typeof end === 'number') {
        setCount(Math.floor(percentage * (end - start) + start));
      } else {
        // For string values like "1,000+"
        const numericEnd = parseInt(end.replace(/\D/g, ''));
        setCount(Math.floor(percentage * (numericEnd - start) + start));
      }

      if (percentage < 1) {
        requestAnimationFrame(animateCount);
      }
    };

    requestAnimationFrame(animateCount);
  }, [end, duration, start]);

  return typeof end === 'string' ? `${count}+` : count;
};
