import React from 'react';

const CTAButton = ({ className = '' }) => {
  return (
    <a
      href="https://cotizador.vendetucatalizador.com.mx"
      className={`
        group bg-white text-blue-600 py-3 px-8 rounded-full text-lg font-semibold 
        hover:bg-blue-50 transition-all duration-300 shadow-lg hover:shadow-xl 
        transform hover:-translate-y-1 relative overflow-hidden inline-block
        ${className}
      `}
    >
      Obtén tu Cotización Ahora
    </a>
  );
};

export default CTAButton;
